import { Config, Env } from '../lib/interface';

export const config: Config = {
  [Env.LOCAL]: {
    authCookieName: '.GGASPXAUTH_STAGING_BEARER',
    jgLogoutUrl: 'https://www.staging.justgiving.com/sso/signout',
  },
  [Env.STAGING]: {
    authCookieName: '.GGASPXAUTH_STAGING_BEARER',
    jgLogoutUrl: 'https://www.staging.justgiving.com/sso/signout',
  },
  [Env.PRODUCTION]: {
    authCookieName: '.GGASPXAUTH_BEARER',
    jgLogoutUrl: 'https://www.justgiving.com/sso/signout',
  },
};

export function getConfig() {
  const key = getEnvFromUrl(window.location.href);

  // eslint-disable-next-line security/detect-object-injection
  return config[key];
}

function getEnvFromUrl(url: string) {
  switch (true) {
    case url.includes('local.justgiving.com'):
      return Env.LOCAL;
    case url.includes('staging.justgiving.com'):
      return Env.STAGING;
    case ['https://justgiving.com', 'https://www.justgiving.com'].some(domain =>
      url.includes(domain),
    ):
      return Env.PRODUCTION;
    default:
      throw new Error(`Url is not part of the justgiving domain: ${url}`);
  }
}
